.App {
  font-family: "Lato", sans-serif;
}

span::first-letter {
  text-transform: capitalize;
}

p::first-letter {
  text-transform: capitalize;
}
