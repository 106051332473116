body {
  margin: 0;
  font-family: "Lato", sans-serif;
  color: #444444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}
html {
  background-color: #f8f8ff;
}

* {
  font-family: "Lato", sans-serif;
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
}

code {
  font-family: "Lato", sans-serif;
}

/* FONT LIST*/

@font-face {
  font-family: "Marcelle Script";
  src: local("Marcelle Script"),
    url(./fonts/MarcelleScript.ttf) format("truetype");
}
